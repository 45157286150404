
<template>
  <div>
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_01.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_02.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_03.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_04.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_05.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_06.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_07.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_08.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_09.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_10.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_11.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_12.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_13.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_14.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_15.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_16.png"
         alt="">
    <img src="@/static/初中智能学伴（自评版）操作指南/初中智能学伴（自评版）操作指南_17.png"
         alt="">
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
div {
  width: 100%;
  height: 100%;
  overflow: auto;
  border-radius: 86rem;
}
img {
  max-width: 1160rem;
}
</style>